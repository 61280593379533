.ResetPasswordPage {
  input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 0;
  }

  .is-invalid {
    border: 1px solid red;
  }

  .form {
    z-index: 1;
    background: #fff;
    max-width: 360px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }

  .reset-button {
    max-height: 53px;
    min-height: 53px;
    text-transform: uppercase;
    outline: 0;
    width: 100%;
    border: 0;
    color: #fff;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
}
